/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-25",
    versionChannel: "nightly",
    buildDate: "2024-04-25T00:05:37.894Z",
    commitHash: "868ab45c98bd8d1ec97b2fcfc2aea7d260240bae",
};
